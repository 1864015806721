<script>
/* eslint vuejs-accessibility/anchor-has-content: 'off' */
import Button from 'primevue/button';
import { DateTime } from 'luxon';
import customProfile from '@/style/profile.json';

export default {
  name: 'FooterModule',
  components: {
    Button,
  },
  computed: {
    currentYear: () => DateTime.now().toFormat('yyyy'),
  },
  data() {
    return {
      profile: customProfile,
    };
  },
};
</script>

<template>
  <div class="surface-section px-4 md:px-6 lg:px-8 text-900">
    <div class="py-6 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
      <div class="text-center md:text-left">
        <img v-if="profile.logo" :src="profile.logo" alt="Image"
          height="40">
        <div class="mt-2 line-height-3 text-center">&copy; {{ currentYear }} {{ profile.company }}</div>
      </div>
      <div class="mt-3 sm:mt-0">
        <div class="flex flex-row justify-content-center text-xl text-center">
          <a v-if="profile.facebook" :href="profile.facebook" target="_blank">
            <Button icon="pi pi-facebook" type="button" class="p-button-text p-button-rounded"></Button>
          </a>
          <a v-if="profile.instagram" :href="profile.instagram" target="_blank">
            <Button icon="pi pi-instagram" type="button" class="p-button-text p-button-rounded ml-3"></Button>
          </a>
        </div>
        <div class="mt-2 line-height-3 text-center"><a href="https://septacode.com" target="_blank">Drivs med SeptaCode</a></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-button {
  color: var(--footer-button-color) !important;
}
.p-button.p-button-text:enabled:hover {
  background: var(--footer-button-hover) !important;
}
</style>
