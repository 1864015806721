import { Notifier } from '@airbrake/browser';

const airbrakePlugin = {
  install(Vue) {
    Vue.config.globalProperties.$airbrake = new Notifier({
      environment: process.env.NODE_ENV,
      projectId: 393956,
      projectKey: process.env.VUE_APP_AIRBRAKE_KEY,
      instrumentation: {
        console: (process.env.NODE_ENV !== 'development'),
      },
    });
    Vue.config.globalProperties.$airbrake.addFilter((notice) => {
      if (notice.context.environment === 'development') {
        return null;
      }
      return notice;
    });
    if (process.env.NODE_ENV !== 'development') {
      Vue.config.errorHandler = (err, vm, info) => {
        Vue.config.globalProperties.$airbrake.notify({
          error: err,
          params: {
            vm,
            info,
          },
        });
      };
      Vue.config.warnHandler = (err, vm, info) => {
        Vue.config.globalProperties.$airbrake.notify({
          error: err,
          params: {
            vm,
            info,
          },
          context: { severity: 'warning' },
        });
      };
    } else {
      Vue.config.errorHandler = (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      };
      Vue.config.warnHandler = (err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      };
    }
  },
};

export default airbrakePlugin;
