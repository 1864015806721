<script>
import { ref } from 'vue';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Avatar from 'primevue/avatar';
import { DateTime } from 'luxon';
import customProfile from '@/style/profile.json';

export default {
  name: 'MetaFeed',
  components: {
    Avatar,
  },
  setup() {
    const loading = ref(true);
    const feedData = ref(null);
    const fetchFeed = httpsCallable(getFunctions(getApp(), 'europe-west3'), 'facebook-fetchFeed');
    fetchFeed().then((feed) => {
      const feedPosts = feed.data;
      feedData.value = feedPosts;
    });
    return {
      loading,
      feedData,
    };
  },
  data() {
    return {
      profile: customProfile,
    };
  },
  methods: {
    formatTimestamp(timestamp) {
      const timeObj = DateTime.fromISO(timestamp);
      const { days, hours, minutes } = DateTime.now().diff(timeObj, ['days', 'hours', 'minutes']);
      if (days > 0 && days >= 8) return timeObj.toFormat('yyyy-MM-dd');
      if (days > 0 && days > 2) return Math.floor(days) + ' dagar sedan';
      if (days > 0 && days < 8) return '1 dag sedan';
      if (hours > 2) return Math.floor(hours) + ' timmar sedan';
      if (hours > 0) return '1 timme sedan';
      if (minutes > 2) return Math.floor(minutes) + ' minuter sedan';
      return '1 minut sedan';
    },
  },
};
</script>

<template>
  <div class="px-4 py-6 md:py-8 md:px-6 lg:px-8 section">
    <div v-if="profile.feed_title" class="font-bold text-5xl title mb-3">{{ profile.feed_title }}</div>
    <div v-if="profile.feed_description" class="description line-height-3 text-xl mb-6">{{ profile.feed_description }}</div>
    <div class="flex flex-column xl:flex-row xl:flex-wrap justify-content-stretch feedMessage">
      <a class="flex shadow-2 surface-card border-round mr-0 no-underline flex-grow-1
        xl:mr-4 xl:mb-6 mb-4 xl:mb-0 flex-column md:flex-row"
        v-for="post in feedData" :key="post.id" :href="post.permalink_url" target="_blank">
        <img v-if="post.full_picture" :src="post.full_picture" alt="Image" class="feedImage border-round-left">
        <div class="p-4 w-full flex flex-column justify-content-between">
          <div v-if="post.story" class="flex justify-content-between mb-3">
            <span class="font-medium">{{ post.story }}</span>
          </div>
          <div v-if="post.message" class="font-normal text-2xl mb-3 line-height-3">{{ post.message }}</div>
          <div v-if="post.call_to_action" class="call-to-action font-normal text-2xl mb-3 line-height-3">
            <div v-if="post.call_to_action.type === 'CALL_NOW'">
              <a :href="post.call_to_action.value.link">
                <div class="flex flex-row align-items-center">
                  <span class="hoverLink">{{ post.from.name }} - Ring nu</span>
                  <i class="pi pi-phone ml-2 callIcon"></i>
                </div>
              </a>
            </div>
            <div v-if="post.call_to_action.type === 'MESSAGE_PAGE'">
              <a :href="'https://m.me/' + post.from.id" target="_blank">
                <div class="flex flex-row align-items-center">
                  <img src="@/style/facebook-messenger.svg" alt="Facebook Messenger" class="mr-2 mIcon">
                  <span class="hoverLink">{{ post.call_to_action.value.link_title }}</span>
                </div>
              </a>
            </div>
          </div>
          <div class="flex justify-content-between meta">
            <a class="flex align-items-center" :href="'https://www.facebook.com/' + post.from.id" target="_blank">
              <Avatar v-if="profile.logo" :image="profile.logo" shape="circle"></Avatar>
              <span class="authorName hoverLink font-bold text-sm block ml-2">{{ post.from.name }}</span>
            </a>
            <span class="authorTime font-medium ml-2 md:ml-4">{{ formatTimestamp(post.created_time) }}</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section {
  background: var(--section-ground);
}
.title {
  color: var(--section-title);
}
.description {
  color: var(--section-description);
}
.feedMessage {
  font-family: 'Segoe UI Historic';
  color: var(--section-text);
}
.feedImage {
  max-width: 100%;
  height: auto;
  max-height: 234px;
  object-fit: cover;
}
.mIcon {
  height: 2rem;
  width: 2rem;
}
.callIcon {
  font-size: 1.5rem;
}
.meta {
  white-space: nowrap;
  line-height: 2rem;
}
.meta:hover .hoverLink {
  text-decoration: underline;
}
.authorName {
  color: var(--section-author-name);
}
.authorTime {
  color: var(--section-author-time);
}
.feedMessage a {
  text-decoration: none;
  color: var(--section-text);
}
.call-to-action:hover .hoverLink {
  text-decoration: underline;
}
</style>
