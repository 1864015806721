// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apiKey = process.env.VUE_APP_FB_API_KEY;
const authDomain = process.env.VUE_APP_FB_AUTH_DOMAIN;

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId: 'septacode-business',
  storageBucket: 'septacode-business.appspot.com',
  messagingSenderId: '565346493662',
  appId: '1:565346493662:web:ea4350aae760eb13324e4f',
};

export default firebaseConfig;
