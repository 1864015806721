<script>
/* eslint vuejs-accessibility/anchor-has-content: 'off' */
import Button from 'primevue/button';
import customProfile from '@/style/profile.json';

export default {
  name: 'HeroModule',
  components: {
    Button,
  },
  data() {
    return {
      profile: customProfile,
      social: [
        'facebook',
        'instagram',
        'google',
        'linkedin',
        'twitter',
        'youtube',
        'whatsapp',
      ],
    };
  },
  methods: {
    rot13: (message) => {
      const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const b = 'nopqrstuvwxyzabcdefghijklmNOPQRSTUVWXYZABCDEFGHIJKLM';
      return message.replace(/[a-z]/gi, (c) => b[a.indexOf(c)]);
    },
  },
};
</script>

<template>
  <div class="grid grid-nogutter hero">
      <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section>
              <span v-if="profile.title" class="block text-6xl title font-bold mb-1">{{ profile.title }}</span>
              <div v-if="profile.subtitle" class="text-5xl subtitle font-bold mb-3">{{ profile.subtitle }}</div>
              <p v-if="profile.description" class="mt-0 mb-4 description line-height-3">{{ profile.description }}</p>
              <div>
                <a v-if="profile.rot13_phone" :href="rot13('gry:' + profile.rot13_phone)">
                  <Button icon="pi pi-phone" type="button" class="mr-3 p-button-raised p-button-rounded p-button-outlined"></Button>
                </a>
                <a v-if="profile.rot13_email" :href="rot13('znvygb:' + profile.rot13_email)">
                  <Button icon="pi pi-envelope" type="button" class="mr-3 p-button-raised p-button-rounded p-button-outlined"></Button>
                </a>
                <a v-if="profile.map" :href="profile.map" target="_blank">
                  <Button icon="pi pi-map" type="button" class="mr-3 p-button-raised p-button-rounded p-button-outlined"></Button>
                </a>
                <a v-if="profile.webstore" :href="profile.webstore" target="_blank">
                  <Button icon="pi pi-shopping-cart" type="button" class="mr-3 p-button-raised p-button-rounded p-button-outlined"></Button>
                </a>
              </div>
              <div class="mt-4">
                <span v-for="media in social" :key="media">
                  <a v-if="profile[media]" :href="profile[media]" target="_blank">
                   <Button :icon="'pi pi-' + media" type="button" class="mr-3 p-button-raised p-button-rounded p-button-outlined"></Button>
                  </a>
                </span>
              </div>
          </section>
      </div>
      <div class="col-12 md:col-6 overflow-hidden">
          <img :src="profile.hero_image" alt="Image" class="heroImage md:ml-auto block md:h-full clippedImage">
      </div>
  </div>
</template>

<style lang="scss" scoped>
.hero {
  background: var(--hero-ground);
}
.title {
  color: var(--hero-title);
}
.subtitle {
  color: var(--hero-subtitle);
}
.description {
  color:  var(--hero-description);
}
.heroImage {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
@media only screen and (min-width: 48rem) {
  .clippedImage {
    clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%);
  }
}
.p-button {
  color: var(--hero-button-color) !important;
}
.p-button.p-button-outlined:enabled:hover {
  background: var(--hero-button-hover) !important;
}
.p-button:enabled:hover {
  background: var(--hero-button-hover) !important;
  border-color: var(--hero-button-color) !important;
}
</style>
